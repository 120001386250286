import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { PolymorphicRef } from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { FocusRing } from '../../common/focus_rings';
import { sizing } from '../../common/sizing';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';

const AlertIcon = styled(Icon, {
  color: '$$iconColor',

  variants: {
    type: {
      full: {
        width: '$16',
        height: '$16',
      },
      inline: {
        width: '$12',
        height: '$12',
      },
    },
  },
});

const AlertHeading = styled(Text, {
  color: '$$headingColor',
  wordBreak: 'break-word',

  variants: {
    type: {
      full: {
        ...BodySansSizes,
        fontWeight: fontWeights.bold,
      },
      inline: {
        ...SmallSansSizes,
        fontWeight: fontWeights.extraBold,
      },
    },
  },
});

const AlertCopy = styled(Text, {
  color: '$$copyColor',
  wordBreak: 'break-word',

  variants: {
    type: {
      full: {
        ...BodySansSizes,
      },
      inline: {
        ...SmallSansSizes,
      },
    },
  },
});

const AlertContent = styled('div', {
  vStack: 4,
  alignItems: 'flex-start',
  width: '100%',
});

const AlertDismissIcon = styled(Icon, {
  '@notDesktop': {
    width: '$12',
    height: '$12',
  },

  '@desktop': {
    width: '$10',
    height: '$10',
  },
});

const AlertDismissContainer = styled('button', FocusRing, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$$backgroundColor',
  strokeAll: colors.transparent,
  color: '$$iconColor',

  [darkThemeSelector]: {
    backgroundColor: '$$backgroundColor',
    color: '$$iconColor',
  },

  '&:hover': {
    backgroundColor: '$$backgroundColor',
    strokeAll: '$$strokeColor',
    color: '$$headingColor',

    [darkThemeSelector]: {
      backgroundColor: '$$backgroundColor',
      strokeAll: '$$strokeColor',
      color: '$$headingColor',
    },
  },

  '@notDesktop': {
    width: '$28',
    height: '$28',
    borderRadius: '$6',
  },

  '@desktop': {
    width: '$20',
    height: '$20',
    borderRadius: '$4',
  },
});

const AlertContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  background: '$$backgroundColor',
  strokeAll: '$$strokeColor',

  variants: {
    id: {},
    isDismissed: {
      true: {
        display: 'none',
      },
    },
    relation: {
      standalone: {},
      stacked: {},
    },
    type: {
      full: {
        padding: sizing.contentSquish,
      },
      inline: {
        padding: `$6 ${sizing.contentSides}`,
      },
    },
    variant: {
      alternative: {
        zIndex: 3,
        $$backgroundColor: colors.bgAlternativeLight,
        $$strokeColor: colors.strokeAlternativeLight,
        $$iconColor: colors.iconAlternativeLight,
        $$headingColor: colors.headingAlternativeLight,
        $$copyColor: colors.bodyAlternativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgAlternativeDark,
          $$strokeColor: colors.strokeAlternativeDark,
          $$iconColor: colors.iconAlternativeDark,
          $$headingColor: colors.headingAlternativeDark,
          $$copyColor: colors.bodyAlternativeDark,
        },
      },
      brand: {
        zIndex: 3,
        $$backgroundColor: colors.bgBrandLight,
        $$strokeColor: colors.strokeBrandLight,
        $$iconColor: colors.iconBrandLight,
        $$headingColor: colors.headingBrandLight,
        $$copyColor: colors.bodyBrandLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgBrandDark,
          $$strokeColor: colors.strokeBrandDark,
          $$iconColor: colors.iconBrandDark,
          $$headingColor: colors.headingBrandDark,
          $$copyColor: colors.bodyBrandDark,
        },
      },
      negative: {
        zIndex: 3,
        $$backgroundColor: colors.bgNegativeLight,
        $$strokeColor: colors.strokeNegativeLight,
        $$iconColor: colors.iconNegativeLight,
        $$headingColor: colors.headingNegativeLight,
        $$copyColor: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNegativeDark,
          $$strokeColor: colors.strokeNegativeDark,
          $$iconColor: colors.iconNegativeDark,
          $$headingColor: colors.headingNegativeDark,
          $$copyColor: colors.bodyNegativeDark,
        },
      },
      attention: {
        zIndex: 3,
        $$strokeColor: colors.strokeAttentionLight,
        $$backgroundColor: colors.bgAttentionLight,
        $$iconColor: colors.iconAttentionLight,
        $$headingColor: colors.headingAttentionLight,
        $$copyColor: colors.bodyAttentionLight,

        [darkThemeSelector]: {
          $$strokeColor: colors.strokeAttentionDark,
          $$backgroundColor: colors.bgAttentionDark,
          $$iconColor: colors.iconAttentionDark,
          $$headingColor: colors.headingAttentionDark,
          $$copyColor: colors.bodyAttentionDark,
        },
      },
      neutral: {
        zIndex: 1,
        $$backgroundColor: colors.bgNeutralLight,
        $$strokeColor: colors.strokeNeutralLight,
        $$iconColor: colors.iconNeutralLight,
        $$headingColor: colors.headingNeutralLight,
        $$copyColor: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNeutralDark,
          $$strokeColor: colors.strokeNeutralDark,
          $$iconColor: colors.iconNeutralDark,
          $$headingColor: colors.headingNeutralDark,
          $$copyColor: colors.bodyNeutralDark,
        },
      },
      positive: {
        zIndex: 3,
        $$backgroundColor: colors.bgPositiveLight,
        $$strokeColor: colors.strokePositiveLight,
        $$iconColor: colors.iconPositiveLight,
        $$headingColor: colors.headingPositiveLight,
        $$copyColor: colors.bodyPositiveLight,

        [darkThemeSelector]: {
          $$strokeColor: colors.strokePositiveDark,
          $$backgroundColor: colors.bgPositiveDark,
          $$iconColor: colors.iconPositiveDark,
          $$headingColor: colors.headingPositiveDark,
          $$copyColor: colors.bodyPositiveDark,
        },
      },
    },
    internal: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      relation: 'standalone',
      type: 'full',
      css: {
        borderRadius: '$8',
      },
    },
    {
      relation: 'standalone',
      type: 'inline',
      css: {
        borderRadius: '$6',
      },
    },
    {
      internal: true,
      css: {
        zIndex: 3,
        $$backgroundColor: colors.internalBgLight,
        $$strokeColor: colors.internalStrokeLight,
        $$iconColor: colors.internalIconLight,
        $$headingColor: colors.internalHeadingLight,
        $$copyColor: colors.internalBodyLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.internalBgDark,
          $$strokeColor: colors.internalStrokeDark,
          $$iconColor: colors.internalIconDark,
          $$headingColor: colors.internalHeadingDark,
          $$copyColor: colors.internalBodyDark,
        },
      },
    },
  ],
});

export interface AlertDismissProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: any) => void;
}

export const AlertDismiss = React.forwardRef<HTMLButtonElement, AlertDismissProps>(
  (props: AlertDismissProps, ref) => (
    <AlertDismissContainer type="button" {...props} ref={ref}>
      <AlertDismissIcon icon="cross" />
    </AlertDismissContainer>
  ),
);

export type AlertRelation = 'stacked' | 'standalone';
export type AlertVariant =
  | 'alternative'
  | 'attention'
  | 'brand'
  | 'negative'
  | 'neutral'
  | 'positive';

export type AlertProps = {
  /**
   * The more detailed information regarding the notice, no value disables no copy.
   */
  copy?: React.ReactNode;
  /**
   * Provide an accessible label for the dismiss action of the alert.
   */
  dismissLabel?: string;
  /**
   * Pass in the onClick event for dismissing the alert.
   */
  dismissOnClick?: (event: any) => void;
  /**
   * A simple heading for the notice, no value disables no heading.
   */
  heading?: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Pass in an ID to make sure the Alert is identifiable.
   */
  id?: React.ReactNode;
  internal?: boolean;
  /**
   * Set whether the alert has been dismissed.
   */
  isDismissed?: boolean;
  /**
   * Set whether the radius should exist or be squared off.
   */
  relation?: AlertRelation;
  /**
   * Actions to be displayed at the bottom of the alert.
   */
  trailingButtons?: React.ReactNode;
  /**
   * Actions to be displayed at the top-right of the alert.
   */
  shoulderButtons?: React.ReactNode;
  /**
   * Determine if the component should be the full or inline type.
   */
  type?: 'full' | 'inline';
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: AlertVariant;
};

export const Alert = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      copy,
      dismissLabel = 'Dismiss',
      dismissOnClick,
      heading,
      icon,
      id,
      internal,
      isDismissed,
      relation = 'standalone',
      shoulderButtons,
      trailingButtons,
      type = 'full',
      variant = 'neutral',
      ...remaining
    }: AlertProps,
    ref: PolymorphicRef<Tag>,
  ) => (
    <AlertContainer
      isDismissed={isDismissed}
      variant={variant}
      relation={relation}
      type={type}
      internal={internal}
      ref={ref}
      {...remaining}
    >
      <AlignStack
        direction="row"
        gap={type === 'full' ? 8 : 6}
        preset={type === 'full' ? 'body' : 'small'}
        start={icon && <AlertIcon icon={icon} type={type} />}
        end={
          (shoulderButtons || dismissOnClick) && (
            <ControlGroup relation="separate" size="small">
              {shoulderButtons}
              {dismissOnClick && (
                <AlertDismiss aria-label={dismissLabel} onClick={dismissOnClick} />
              )}
            </ControlGroup>
          )
        }
      >
        {(heading || copy || trailingButtons) && (
          <AlertContent>
            {heading && <AlertHeading type={type}>{heading}</AlertHeading>}
            {copy && <AlertCopy type={type}>{copy}</AlertCopy>}
            {trailingButtons && (
              <ControlGroup relation="separate" size="small">
                {trailingButtons}
              </ControlGroup>
            )}
          </AlertContent>
        )}
      </AlignStack>
    </AlertContainer>
  ),
);
