import { colors, darkThemeSelector, fonts, fontWeights, globalCss } from './stitches.config';

export const injectGlobalAttoStyles = globalCss({
  body: {
    background: colors.bgApplicationLight,
    color: colors.bodyNeutralLight,
    fontSize: 16,
    fontFamily: fonts.sans,
    fontWeight: fontWeights.regular,

    [darkThemeSelector]: {
      color: colors.bodyNeutralDark,
      background: colors.bgApplicationDark,
    },

    // Enable improved font rendering only on high-DPI displays
    '@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)':
      {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },

    // forces full height in storybook so elements in portals aren't clipped
    '&.sb-show-main': {
      minHeight: '100vh',
    },
  },
});
