import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { CheckboxInputProps } from '../../components/CheckboxInput/CheckboxInput';
import type { BaseFieldProps } from './common/types';
import { CheckboxInput } from '../../components/CheckboxInput/CheckboxInput';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { isDefined } from '../../utilities/isDefined';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { PrimaryFieldDescription, PrimaryFieldLabel } from './common/FieldLabel';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';

const Container = styled('div', {
  display: 'grid',
  gap: 0,
  columnGap: '$8',
  alignItems: 'stretch',
  gridTemplateColumns: 'min-content auto',
  gridTemplateRows: 'fit-content fit-content',
  gridTemplateAreas: '"control label" "none help-text"',
});

const Control = styled('div', {
  gridArea: 'control',
  display: 'flex',
  alignItems: 'center',
  minHeight: '$20',
  maxHeight: '$20',
});

const Label = styled('div', {
  gridArea: 'label',
  vStack: 0,
  width: '100%',
  alignItems: 'stretch',
});

const HelpText = styled('div', {
  gridArea: 'help-text',
  vStack: 0,
  alignItems: 'stretch',
});

const ErrorText = styled('div', Small, {
  display: 'flex',
  marginTop: '$2',
  padding: '$4 $6',
  backgroundColor: colors.red50,
  borderRadius: '$6',
  color: colors.red700,
  strokeAll: colors.red100,

  [darkThemeSelector]: {
    backgroundColor: colors.red900,
    color: colors.red50,
    strokeAll: colors.red800,
  },
});

export interface MinimalCheckboxFieldProps extends BaseFieldProps, CheckboxInputProps {}

export function MinimalCheckboxField(props: MinimalCheckboxFieldProps) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    errorMessage = customFieldProps.errorMessage,
    description,
    ...extraInputProps
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  return (
    <Container>
      <Control>
        <CheckboxInput {...mergeProps(customInputProps, inputProps, extraInputProps)} />
      </Control>
      <Label>
        <PrimaryFieldLabel {...labelProps}>{label}</PrimaryFieldLabel>
      </Label>
      {(isDefined(description) || isDefined(errorMessage)) && (
        <HelpText>
          <PrimaryFieldDescription {...descriptionProps}>{description}</PrimaryFieldDescription>
          {errorMessage && (
            <ErrorText {...errorMessageProps}>{filterErrorMessage(errorMessage)}</ErrorText>
          )}
        </HelpText>
      )}
    </Container>
  );
}
