import type { ComponentProps } from 'react';
import React from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { BadgeGroup } from '../../components/Badge/BadgeGroup';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';

const slotWidth = 32;
const slotHeight = 44;

const SlotConnectionIcon = styled(Icon, {
  width: '$16',
  height: '$16',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  variants: {
    active: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
  },
});
SlotConnectionIcon.displayName = 'SlotConnectionIcon';

const SlotConnectionLabel = styled(Small, {
  truncate: true,
  width: '100%',
  minWidth: '$40',
  color: colors.headingNeutralLight,
  fontWeight: fontWeights.extraBold,
  textAlign: 'center',

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },

  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
  },
});
SlotConnectionLabel.displayName = 'SlotConnectionLabel';

const SlotConnectionPosition = styled('div', {
  position: 'relative',
  zIndex: 4,
  vStack: '$0',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$48',
  height: '$48',
  padding: '0 $4',
  backgroundColor: colors.bgApplicationLight,
  borderRadius: '$8',
  boxShadow: shadows.connectionLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    boxShadow: shadows.connectionDark,
  },

  [selectors.focus]: {
    boxShadow: shadows.focusRingLight,
  },

  variants: {
    active: {
      true: {
        backgroundColor: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
      false: {},
    },
  },
});
SlotConnectionPosition.displayName = 'SlotConnectionPosition';

const SlotConnectionChain = styled('div', {
  position: 'relative',
  zIndex: 3,
  width: '$2',
  height: '$8',
  marginLeft: '-$1',
  backgroundColor: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
});
SlotConnectionChain.displayName = 'SlotConnectionChain';

const SlotConnectionContainer = styled('div', {
  position: 'absolute',
  zIndex: 2,
  left: '-$4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$40',

  [selectors.hover]: {
    zIndex: 3,

    [`& ${SlotConnectionPosition}`]: {
      width: 'auto',
      maxWidth: '$320',
      height: '$56',
      padding: '0 $8',
    },

    [`& ${SlotConnectionChain}`]: {
      height: '$4',
    },
  },

  [selectors.focus]: {
    zIndex: 2,
    outline: 'none',

    [`& ${SlotConnectionPosition}`]: {
      boxShadow: shadows.focusRingLight,

      [darkThemeSelector]: {
        boxShadow: shadows.focusRingDark,
      },
    },
  },

  variants: {
    orientation: {
      top: {
        top: '-$56',
        flexDirection: 'column',

        [selectors.hover]: {
          top: '-$60',
        },
      },
      bottom: {
        bottom: `-$${slotHeight + 12}`,
        flexDirection: 'column-reverse',

        [selectors.hover]: {
          bottom: '-$60',
        },
      },
    },
  },
});
SlotConnectionContainer.displayName = 'SlotConnectionContainer';

export type SlotConnectionProps = {
  active?: boolean;
  hardware:
    | 'access-point'
    | 'isp'
    | 'pdu'
    | 'power-distribution-unit'
    | 'security-appliance'
    | 'switch';
  label: React.ReactNode;
  onClick?: (event: any) => void;
  orientation?: 'bottom' | 'top';
};

function SlotConnection({
  active = false,
  hardware,
  label,
  onClick,
  orientation = 'top',
  ...remaining
}: SlotConnectionProps) {
  return (
    <SlotConnectionContainer
      onClick={onClick}
      tabIndex={onClick && 0}
      role={onClick && 'button'}
      orientation={orientation}
      {...remaining}
    >
      <SlotConnectionPosition active={active}>
        {hardware && (
          <SlotConnectionIcon active={active} icon={hardware === 'isp' ? 'globe' : hardware} />
        )}
        <SlotConnectionLabel active={active}>{label}</SlotConnectionLabel>
      </SlotConnectionPosition>
      <SlotConnectionChain />
    </SlotConnectionContainer>
  );
}

const SlotNumber = styled('span', {
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 $2',
  background: colors.bgNeutralLight,
  borderRadius: '$4',
  color: colors.bodyNeutralLight,
  fontFamily: '$mono',
  fontSize: '$10',
  fontWeight: '$bold',
  lineHeight: '$12',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    color: colors.bodyNeutralDark,
  },

  variants: {
    active: {
      true: {
        background: colors.bgBrandLight,
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          background: colors.bgBrandDark,
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
  },
});
SlotNumber.displayName = 'SlotNumber';

const SlotDiagram = styled('div', {
  zIndex: 1,
  width: `${slotWidth}`,
  maxWidth: `${slotWidth}`,
  minWidth: `${slotWidth}`,
  height: '$28',
  maxHeight: '$28',
  minHeight: '$28',
});
SlotDiagram.displayName = 'SlotDiagram';

const SlotTarget = styled('div', {
  position: 'relative',
  display: 'flex',
  gap: '$4',

  '&::before': {
    content: '',
    position: 'absolute',
    inset: '-$4',
    display: 'block',
    borderRadius: '$8',
  },

  [selectors.focus]: {
    outline: 'none',

    '&::before': {
      boxShadow: shadows.focusRingLight,

      [darkThemeSelector]: {
        boxShadow: shadows.focusRingDark,
      },
    },
  },

  variants: {
    active: {
      true: {
        '&::before': {
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
      false: {},
    },
    orientation: {
      top: {
        flexDirection: 'column-reverse',
      },
      bottom: {
        flexDirection: 'column',
      },
    },
  },
});
SlotTarget.displayName = 'SlotTarget';

const SlotBadge = styled(BadgeGroup, {
  position: 'absolute',
  zIndex: 3,
  left: '50%',

  variants: {
    orientation: {
      top: {
        bottom: 0,
        transform: 'translate(-50%, 50%)',
      },
      bottom: {
        top: 0,
        transform: 'translate(-50%, -50%)',
      },
    },
  },
});

const SlotContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  width: 'fit-content',
  height: slotHeight,
});
SlotContainer.displayName = 'SlotContainer';

type SlotProps = {
  active?: boolean;
  badges?: React.ReactNode;
  connection?: SlotConnectionProps;
  diagram: React.ReactNode;
  onClick?: (event: any) => void;
  orientation?: 'top' | 'bottom';
  label?: string | null;
  number?: number;
} & ComponentProps<typeof SlotContainer>;

function Slot({
  active,
  badges,
  connection,
  diagram,
  label,
  number,
  onClick,
  orientation = 'bottom',
  ...remaining
}: SlotProps) {
  return (
    <SlotContainer {...remaining}>
      {connection && (
        <SlotConnection orientation={orientation === 'top' ? 'bottom' : 'top'} {...connection} />
      )}
      {badges && (
        <SlotBadge orientation={orientation} relation="joined" size="small">
          {badges}
        </SlotBadge>
      )}
      <SlotTarget
        onClick={onClick}
        tabIndex={onClick && 0}
        role={onClick && 'button'}
        active={active}
        orientation={orientation}
      >
        <SlotDiagram>{diagram}</SlotDiagram>
        <SlotNumber active={active}>{label ?? number}</SlotNumber>
      </SlotTarget>
    </SlotContainer>
  );
}

const SlotsContainer = styled('div', {
  gap: '$8',
  width: 'fit-content',

  variants: {
    align: {
      top: {},
      bottom: {
        alignSelf: 'flex-end',
      },
    },
    rows: {
      1: {
        display: 'flex',
        flexDirection: 'row',
      },
      2: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: `repeat(auto-fit, $${slotWidth})`,
        gridTemplateRows: `repeat(2, $${slotHeight})`,
      },
    },
  },
});
SlotsContainer.displayName = 'SlotsContainer';

type SlotsProps = {
  align?: 'top' | 'bottom';
  start?: number;
  slots: SlotProps[];
  rows?: 1 | 2;
  orientation?: 'top' | 'bottom';
} & ComponentProps<typeof SlotsContainer>;

export function Slots({
  align,
  rows = 1,
  slots,
  start = 0,
  orientation,
  ...remaining
}: SlotsProps) {
  return (
    <SlotsContainer align={align} rows={rows} {...remaining}>
      {slots.map((slot, index) => {
        const isOdd = index % 2 === 0;
        const key = start + index;
        return (
          <Slot
            key={`slot-${key}`}
            orientation={orientation ?? (rows !== 1 && !isOdd ? 'top' : undefined)}
            number={index + start}
            {...slot}
          />
        );
      })}
    </SlotsContainer>
  );
}
