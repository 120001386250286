import React, { type ComponentProps, useId } from 'react';

import type { VariantProp } from '../../common/colors';
import type { GutterProp } from '../../common/sizing';
import { sizing } from '../../common/sizing';
import { VariantProvider } from '../../common/variant';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body, BodySansSizes } from '../../text/Body';
import { LargeSansSizes } from '../../text/Large';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { gutterCSS } from '../../utilities/shared/Gutter';
import { LabelTooltip } from '../Tooltip/Tooltip';

const StatDisplay = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  height: '100%',
  color: '$$displayColor',

  variants: {
    alignment: {
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      start: {
        justifyContent: 'flex-start',
      },
    },
    size: {
      small: {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
      large: {
        ...LargeSansSizes,
      },
    },
  },
});

const StatLabel = styled(Text, {
  color: '$$labelColor',
  whiteSpace: 'nowrap',
});

const StatLegend = styled(Body, {
  display: 'flex',
  flexDirection: 'row',
  color: '$$legendColor',
});

const StatHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '$8',
});

const StatContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  $$legendColor: colors.bodyNeutralLight,
  $$displayColor: colors.headingNeutralLight,

  [darkThemeSelector]: {
    $$legendColor: colors.bodyNeutralDark,
    $$displayColor: colors.headingNeutralDark,
  },

  variants: {
    alignment: {
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      start: {
        alignItems: 'flex-start',
      },
    },
    arrangement: {
      'leading-display': {
        flexDirection: 'column-reverse',
      },
      'leading-label': {
        flexDirection: 'column',
      },
    },
    selected: {
      true: {
        $$labelColor: colors.bodyBrandLight,
        $$legendColor: colors.bodyBrandLight,
        $$displayColor: colors.headingBrandLight,

        [darkThemeSelector]: {
          $$labelColor: colors.bodyBrandDark,
          $$legendColor: colors.bodyBrandDark,
          $$displayColor: colors.headingBrandDark,
        },
      },
      false: {},
    },
    size: {
      small: {
        gap: '$2',
      },
      medium: {
        gap: '$4',
      },
      large: {
        gap: '$4',
      },
    },
    ticker: {
      true: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: '$8',
      },
      false: {},
    },
  },
});

type StatAlignmentProp = 'center' | 'end' | 'start';
type StatArrangementProp = 'leading-display' | 'leading-label';
type StatSizeProp = 'small' | 'medium' | 'large';

export type StatProps = {
  alignment?: StatAlignmentProp;
  arrangement?: StatArrangementProp;
  label: React.ReactNode;
  legend?: React.ReactNode;
  selected?: boolean;
  size?: StatSizeProp;
  ticker?: boolean;
  tooltip?: React.ReactNode;
  value: React.ReactNode;
  variant?: VariantProp;
} & ComponentProps<typeof StatContainer>;

function Stat({
  alignment,
  arrangement,
  label,
  legend,
  selected,
  size,
  ticker,
  tooltip,
  value,
  variant,
  ...remaining
}: StatProps) {
  return (
    <StatContainer
      alignment={alignment}
      arrangement={arrangement}
      selected={selected}
      size={size}
      ticker={ticker}
      {...remaining}
    >
      <StatHeader>
        <LabelTooltip
          contents={tooltip}
          preset={size === 'large' ? 'body' : 'small'}
          variant={variant}
        >
          <StatLabel>{label}</StatLabel>
        </LabelTooltip>
        {legend && <StatLegend>{legend}</StatLegend>}
      </StatHeader>
      <StatDisplay alignment={alignment} size={size}>
        <VariantProvider value={variant}>{value}</VariantProvider>
      </StatDisplay>
    </StatContainer>
  );
}

const StatsSeparator = styled('div', {
  display: 'flex',
  width: '$2',
  height: '$16',
  backgroundColor: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
});

const StatsContainer = styled('div', {
  position: 'relative',
  width: '100%',
  padding: sizing.squish,

  variants: {
    gutter: {
      all: {},
      vertical: {},
      horizontal: {},
      top: {},
      right: {},
      bottom: {},
      left: {},
      none: {},
    },
    proportions: {
      auto: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      equal: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
      },
    },
    size: {
      small: {
        gap: '$4',
      },
      medium: {
        gap: '$8',
      },
      large: {
        gap: '$12',
      },
    },
    ticker: {
      true: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: '$16',
        overflow: 'auto',
      },
      false: {},
    },
  },
});

export type StatsProps = {
  alignment?: StatAlignmentProp;
  arrangement?: StatArrangementProp;
  gutter?: GutterProp;
  proportions?: 'equal' | 'auto';
  selected?: boolean;
  size?: StatSizeProp;
  stats: StatProps[];
  ticker?: boolean;
} & ComponentProps<typeof StatsContainer>;

export function Stats({
  alignment = 'start',
  gutter = 'all',
  arrangement = 'leading-label',
  proportions = 'equal',
  selected,
  size = 'large',
  stats,
  ticker = false,
  ...remaining
}: StatsProps) {
  const id = useId();
  return (
    <StatsContainer
      proportions={proportions}
      size={size}
      ticker={ticker}
      css={gutterCSS({ context: size === 'small' ? 'content' : 'application', gutter })}
      {...remaining}
    >
      {stats.map((stat) => (
        <React.Fragment key={`${id}-${stat.label}`}>
          <Stat
            alignment={alignment}
            arrangement={arrangement}
            selected={selected}
            size={size}
            ticker={ticker}
            {...stat}
          />
          {ticker && stats.indexOf(stat) !== stats.length - 1 && <StatsSeparator />}
        </React.Fragment>
      ))}
    </StatsContainer>
  );
}
