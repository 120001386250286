import type { ButtonHTMLAttributes } from 'react';
import React, { useId } from 'react';

import type { DropdownMenuItemProps } from '../../components/DropdownMenu/DropdownMenu';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '../../components/DropdownMenu/DropdownMenu';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { Button } from '../Button/Button';
import { ControlGroup } from '../ControlGroup/ControlGroup';
import { selectors } from '../shared/styles';

const BreadcrumbHomeIcon = styled(Icon, {
  width: '$12',
  height: '$12',
  color: '$$iconColor',
});

const BreadcrumbLabel = styled(Small, {
  color: '$$labelColor',
  fontWeight: fontWeights.extraBold,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const BreadcrumbPageContainer = styled('button', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$4 $6',
  minWidth: 0,
  borderRadius: '$8',
  cursor: 'pointer',
  $$labelColor: colors.bodyNeutralLight,
  $$iconColor: colors.iconNeutralLight,

  [darkThemeSelector]: {
    $$labelColor: colors.bodyNeutralDark,
    $$iconColor: colors.iconNeutralDark,
  },

  [selectors.hover]: {
    $$labelColor: colors.headingNeutralLight,
    $$iconColor: colors.bodyNeutralLight,

    [darkThemeSelector]: {
      $$labelColor: colors.headingNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
    },
  },

  [selectors.focus]: {
    zIndex: 3,
    outline: 'none',
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    onClick: {
      true: {},
    },
    selected: {
      true: {
        zIndex: 2,
        $$labelColor: colors.bodyBrandLight,
        $$iconColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          $$labelColor: colors.bodyBrandDark,
          $$iconColor: colors.iconBrandDark,
        },
      },
      false: {
        zIndex: 1,
      },
    },
  },
});

const BreadcrumbFieldContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  minWidth: 0,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 $4',
});

const BreadcrumbStepper = styled(Icon, {
  width: '$8',
  height: '$8',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  '&:last-child': {
    display: 'none',
  },
});
export interface BreadcrumbPageProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: any;
  to?: any;
  /**
   * The label of the breadcrumb page.
   */
  label: React.ReactNode;
  /**
   * Whether the breadcrumb page is selected.
   */
  selected?: boolean;
}

function BreadcrumbPageInner<Tag extends React.ElementType>(
  { label, selected, ...remaining }: PolymorphicComponentProps<Tag, BreadcrumbPageProps>,
  ref: PolymorphicRef<Tag>,
) {
  return (
    <BreadcrumbPageContainer
      {...(remaining as object)}
      ref={ref}
      role="tab"
      aria-selected={selected ? 'true' : 'false'}
      selected={selected}
    >
      <BreadcrumbLabel>{label}</BreadcrumbLabel>
    </BreadcrumbPageContainer>
  );
}

function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement,
): (props: P & React.RefAttributes<T>) => React.ReactElement {
  return React.forwardRef(render) as any;
}

export const BreadcrumbPage = fixedForwardRef(BreadcrumbPageInner);

export interface BreadcrumbFieldProps {
  field: React.ReactNode;
}

function BreadcrumbField({ field }: BreadcrumbFieldProps) {
  return <BreadcrumbFieldContainer>{field}</BreadcrumbFieldContainer>;
}

export type BreadcrumbPropType = 'field' | 'page';
export interface BreadcrumbProps {
  type?: BreadcrumbPropType;
  field?: BreadcrumbFieldProps;
  page?: BreadcrumbPageProps;
}

export function Breadcrumb({ type, field, page }: BreadcrumbProps) {
  if (type === 'field') {
    return <BreadcrumbField field={field?.field} {...field} />;
  }
  return <BreadcrumbPage label={page?.label} selected={page?.selected} {...page} />;
}

const BreadcrumbsCrumbs = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const BreadcrumbsContainer = styled('nav', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

export type BreadcrumbsPropBack = React.MouseEventHandler<HTMLButtonElement>;
export type BreadcrumbsPropHome = Omit<BreadcrumbPageProps, 'label'>;
export type BreadcrumbsPropHistory = DropdownMenuItemProps;

export type BreadcrumbsProps = {
  back?: BreadcrumbsPropBack;
  crumbs?: BreadcrumbProps[];
  home?: BreadcrumbsPropHome;
  history?: BreadcrumbsPropHistory[];
};

export function Breadcrumbs({ back, crumbs, home, history, ...remaining }: BreadcrumbsProps) {
  const uuid = useId();
  return (
    <BreadcrumbsContainer aria-label="Breadcrumbs" role="tablist" {...remaining}>
      {(history || back) && (
        <ControlGroup relation="joined" size="small" variant="secondary">
          {history && history.length > 0 && (
            <DropdownMenu>
              <DropdownMenuButton arrangement="hidden-label" icon="clock">
                History
              </DropdownMenuButton>
              <DropdownMenuPopover>
                <DropdownMenuGroup label="History">
                  {history.map((historyItem) => (
                    <DropdownMenuItem {...historyItem} />
                  ))}
                </DropdownMenuGroup>
              </DropdownMenuPopover>
            </DropdownMenu>
          )}
          {back && (
            <Button arrangement="hidden-label" icon="chevron-left" onClick={back}>
              Back
            </Button>
          )}
        </ControlGroup>
      )}
      {(home || crumbs) && (
        <BreadcrumbsCrumbs>
          {home && (
            <>
              <BreadcrumbPage {...home} label={<BreadcrumbHomeIcon icon="home" />} />
              <BreadcrumbStepper icon="chevron-right" />
            </>
          )}
          {crumbs &&
            crumbs.map((crumb, index) => (
              <React.Fragment key={`breadcrumb-${crumb.page?.label || crumb.type}-${uuid}`}>
                <Breadcrumb {...crumb} />
                {index !== crumbs.length - 1 && <BreadcrumbStepper icon="chevron-right" />}
              </React.Fragment>
            ))}
        </BreadcrumbsCrumbs>
      )}
    </BreadcrumbsContainer>
  );
}
