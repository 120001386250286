import type { SVGAttributes } from 'react';
import { IconSVG } from 'atto-svgs';
import { merge } from 'lodash-es';
import React from 'react';

import type { VariantProp } from '../../common/colors';
import type { SpacingValue } from '../../common/sizing';
import type { ColorProps } from '../../utilities/shared/Color';
import { styled } from '../../stitches.config';
import { colorCSS } from '../../utilities/shared/Color';

export type IconSize = SpacingValue;

export const Icons = {
  'access-control': IconSVG.AccessControl,
  'access-point': IconSVG.AccessPoint,
  'access-points': IconSVG.AccessPoints,
  'access-point-backup': IconSVG.AccessPointBackup,
  'access-point-cycle': IconSVG.AccessPointCycle,
  'access-point-scan': IconSVG.AccessPointScan,
  airplay: IconSVG.AirPlay,
  antenna: IconSVG.Antenna,
  application: IconSVG.Application,
  area: IconSVG.Area,
  'arrow-bidirectional': IconSVG.ArrowBidirectional,
  'arrow-down': IconSVG.ArrowDown,
  'arrow-left': IconSVG.ArrowLeft,
  'arrow-right': IconSVG.ArrowRight,
  'arrows-up-down': IconSVG.ArrowsUpDown,
  'arrows-diagonal': IconSVG.ArrowsDiagonal,
  'arrows-diagonal-reverse': IconSVG.ArrowsDiagonalReverse,
  'arrows-move': IconSVG.ArrowsMove,
  'arrows-rotate': IconSVG.ArrowsRotate,
  'arrow-up': IconSVG.ArrowUp,
  'arrow-up-right': IconSVG.ArrowUpRight,
  attach: IconSVG.Attach,
  attention: IconSVG.Attention,
  backspace: IconSVG.Backspace,
  bell: IconSVG.Bell,
  'bell-crossed': IconSVG.BellCrossed,
  'bell-filled': IconSVG.BellFilled,
  beta: IconSVG.Beta,
  block: IconSVG.Block,
  bookmark: IconSVG.Bookmark,
  'bookmark-filled': IconSVG.BookmarkFilled,
  'bookmark-one': IconSVG.BookmarkOne,
  'bookmark-three': IconSVG.BookmarkThree,
  'bookmark-two': IconSVG.BookmarkTwo,
  calendar: IconSVG.Calendar,
  'captive-portal': IconSVG.CaptivePortal,
  cards: IconSVG.Cards,
  changelog: IconSVG.Changelog,
  channel: IconSVG.Channel,
  'channel-cycle': IconSVG.ChannelCycle,
  'chart-bar': IconSVG.ChartBar,
  'chart-line': IconSVG.ChartLine,
  chat: IconSVG.Chat,
  checkmark: IconSVG.Checkmark,
  'checkmark-circle': IconSVG.CheckmarkCircle,
  'chevron-down': IconSVG.ChevronDown,
  'chevron-left': IconSVG.ChevronLeft,
  'chevron-right': IconSVG.ChevronRight,
  'chevrons-vertical': IconSVG.ChevronsVertical,
  'chevron-up': IconSVG.ChevronUp,
  client: IconSVG.Client,
  'client-vpn': IconSVG.ClientVPN,
  clock: IconSVG.Clock,
  code: IconSVG.Code,
  cog: IconSVG.Cog,
  columns: IconSVG.Columns,
  command: IconSVG.Command,
  company: IconSVG.Company,
  compass: IconSVG.Compass,
  connection: IconSVG.Connection,
  'connection-connected': IconSVG.ConnectionConnected,
  'connection-disassociated': IconSVG.ConnectionDisassociated,
  'connection-failed': IconSVG.ConnectionFailed,
  console: IconSVG.Console,
  copy: IconSVG.Copy,
  cross: IconSVG.Cross,
  'cross-circle': IconSVG.CrossCircle,
  data: IconSVG.Data,
  'data-cycle': IconSVG.DataCycle,
  dhcp: IconSVG.DHCP,
  dns: IconSVG.DNS,
  'dns-security': IconSVG.DNSSecurity,
  dock: IconSVG.Dock,
  document: IconSVG.Document,
  download: IconSVG.Download,
  email: IconSVG.Email,
  ethernet: IconSVG.EthernetUp,
  'ethernet-down': IconSVG.EthernetDown,
  'ethernet-down-cycle': IconSVG.EthernetDownCycle,
  'ethernet-up': IconSVG.EthernetUp,
  'ethernet-up-cycle': IconSVG.EthernetUpCycle,
  'eye-closed': IconSVG.EyeClosed,
  'eye-open': IconSVG.EyeOpen,
  filter: IconSVG.Filter,
  firewall: IconSVG.Firewall,
  flag: IconSVG.Flag,
  'flash-leds': IconSVG.FlashLEDs,
  'floor-plan': IconSVG.FloorPlan,
  gavel: IconSVG.Gavel,
  globe: IconSVG.Globe,
  'globe-one': IconSVG.GlobeOne,
  'globe-two': IconSVG.GlobeTwo,
  'globe-three': IconSVG.GlobeThree,
  heart: IconSVG.Heart,
  home: IconSVG.Home,
  'host-monitoring': IconSVG.HostMonitoring,
  'hotspot-2.0': IconSVG.Hotspot20,
  hub: IconSVG.Hub,
  information: IconSVG.Information,
  insights: IconSVG.Insights,
  inspector: IconSVG.Inspector,
  integrations: IconSVG.Integrations,
  'ip-address': IconSVG.IPAddress,
  'ipsec-tunnel': IconSVG.IPSecTunnel,
  'life-ring': IconSVG.LifeRing,
  lightning: IconSVG.Lightning,
  'lightning-cycle': IconSVG.LightningCycle,
  list: IconSVG.List,
  location: IconSVG.Location,
  locations: IconSVG.Locations,
  log: IconSVG.Log,
  'mac-address': IconSVG.MACAddress,
  megaphone: IconSVG.Megaphone,
  menu: IconSVG.Menu,
  meter: IconSVG.Meter,
  microchip: IconSVG.Microchip,
  'microchip-upgrade': IconSVG.MicrochipUpgrade,
  minus: IconSVG.Minus,
  'minus-circle': IconSVG.MinusCircle,
  'multicast-dns': IconSVG.MulticastDNS,
  nat: IconSVG.NAT,
  'network-wide': IconSVG.NetworkWide,
  noise: IconSVG.Noise,
  note: IconSVG.Note,
  onboarding: IconSVG.Onboarding,
  operator: IconSVG.Operator,
  'overflow-horizontal': IconSVG.OverflowHorizontal,
  'overflow-vertical': IconSVG.OverflowVertical,
  'packet-capture': IconSVG.PacketCapture,
  padlock: IconSVG.Padlock,
  'pane-bottom': IconSVG.PaneBottom,
  'pane-end': IconSVG.PaneEnd,
  'pane-left': IconSVG.PaneLeft,
  'pane-right': IconSVG.PaneRight,
  pdu: IconSVG.PDU,
  pencil: IconSVG.Pencil,
  phone: IconSVG.Phone,
  ping: IconSVG.Ping,
  placeholder: IconSVG.Placeholder,
  play: IconSVG.Play,
  plus: IconSVG.Plus,
  'plus-circle': IconSVG.PlusCircle,
  'port-forward': IconSVG.PortForward,
  power: IconSVG.Power,
  'power-cycle': IconSVG.PowerCycle,
  'power-distribution-unit': IconSVG.PDU,
  preferences: IconSVG.Preferences,
  printer: IconSVG.Printer,
  'priority-highest': IconSVG.PriorityHighest,
  'priority-high': IconSVG.PriorityHigh,
  'priority-medium': IconSVG.PriorityMedium,
  'priority-low': IconSVG.PriorityLow,
  'priority-lowest': IconSVG.PriorityLowest,
  pulse: IconSVG.Pulse,
  qrcode: IconSVG.QRCode,
  question: IconSVG.Question,
  rack: IconSVG.Rack,
  'radio-profile': IconSVG.RadioProfile,
  radius: IconSVG.Radius,
  'rate-limit': IconSVG.RateLimit,
  reserve: IconSVG.Reserve,
  reset: IconSVG.Reset,
  ribbon: IconSVG.Ribbon,
  rocket: IconSVG.Rocket,
  route: IconSVG.Route,
  rules: IconSVG.Rules,
  save: IconSVG.Save,
  search: IconSVG.Search,
  'search-domain': IconSVG.SearchDomain,
  'search-scoped': IconSVG.SearchScoped,
  'search-service': IconSVG.SearchService,
  secure: IconSVG.Secure,
  'security-appliance': IconSVG.SecurityAppliance,
  service: IconSVG.Service,
  share: IconSVG.Share,
  'site-to-site': IconSVG.SiteToSite,
  outlet: IconSVG.Outlet,
  sort: IconSVG.Sort,
  speaker: IconSVG.Speaker,
  'speed-high': IconSVG.SpeedHigh,
  'speed-low': IconSVG.SpeedLow,
  'speed-medium': IconSVG.SpeedMedium,
  ssid: IconSVG.SSID,
  star: IconSVG.Star,
  'star-filled': IconSVG.StarFilled,
  switch: IconSVG.Switch,
  tablet: IconSVG.Tablet,
  tag: IconSVG.Tag,
  toolbox: IconSVG.Toolbox,
  topology: IconSVG.Topology,
  'traffic-shaping': IconSVG.TrafficShaping,
  'trash-can': IconSVG.TrashCan,
  unhealthy: IconSVG.Unhealthy,
  upgrading: IconSVG.Upgrading,
  upload: IconSVG.Upload,
  user: IconSVG.User,
  version: IconSVG.Version,
  view: IconSVG.View,
  'virtual-device': IconSVG.VirtualDevice,
  vlan: IconSVG.VLAN,
  vpn: IconSVG.VPN,
  warning: IconSVG.Warning,
  webhook: IconSVG.Webhook,
  width: IconSVG.Width,
  wifi: IconSVG.WiFi,
  'wifi-qr': IconSVG.WiFiQR,
  wired: IconSVG.Wired,
  wireguard: IconSVG.WireGuard,
  workbench: IconSVG.Workbench,
  wrench: IconSVG.Wrench,
};

export const IconValues = Object.keys(Icons);
export type IconName = keyof typeof Icons;
export const IconNames = Object.keys(Icons) as IconName[];

export interface IconProps {
  /**
   * Set an HTML classname.
   */
  className?: string;
  /**
   * Accepts any color value from our [colorset](/?path=/docs/colors).
   */
  color?: ColorProps;
  /**
   * Set which icon to display.
   */
  icon: IconName;
  /**
   * Set whether the icon is internal.
   */
  internal?: boolean;
  /**
   * Set a size of the component.
   */
  size?: IconSize;
  /**
   * Set a variant of the icon.
   */
  variant?: VariantProp;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
  color: `var(---iconColor, currentColor)`,
});

const getIconVariantColors = (variant: VariantProp) => {
  switch (variant) {
    case 'alternative':
      return colorCSS('iconAlternativeDark', 'iconAlternativeLight');
    case 'attention':
      return colorCSS('iconAttentionDark', 'iconAttentionLight');
    case 'brand':
      return colorCSS('iconBrandDark', 'iconBrandLight');
    case 'negative':
      return colorCSS('iconNegativeDark', 'iconNegativeLight');
    case 'positive':
      return colorCSS('iconPositiveDark', 'iconPositiveLight');
    case 'neutral':
    default:
      return colorCSS('iconNeutralDark', 'iconNeutralLight');
  }
};

export function Icon({
  className,
  color,
  icon,
  internal,
  size = 16,
  variant,
  ...remaining
}: IconProps) {
  const NamedIcon = Icons[icon] as React.ComponentType<SVGAttributes<SVGSVGElement>> | undefined;

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  const cssFromProps = colorCSS(color?.dark, color?.light);

  return (
    <BaseSVG
      as={NamedIcon}
      aria-hidden
      width={size}
      height={size}
      className={className}
      css={merge(
        internal ? colorCSS('internalIconDark', 'internalIconLight') : undefined,
        !internal && variant ? getIconVariantColors(variant) : cssFromProps,
        cssFromProps,
      )}
      {...remaining}
    />
  );
}
